import styled, {
  createGlobalStyle
} from 'styled-components';

export const StudentGlobalStyles = createGlobalStyle `
body {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #262626;
  line-height: 24px;
  -webkit-overflow-scrolling: touch;
  background: #07391d;

}

h1, h2, h3, h4, h5, h6, h7 {
  color: #262626;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}
.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}
.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}
.scrollbar-thumb {
  border-radius: 0 !important;
}
.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}
.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}
/* Instagram Modal */
.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}
@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}
@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}
.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}
.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}
.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}
.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
/********** Add Your Global RTL CSS Here **********/
/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}
/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}
/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}
/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}
/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}
/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}
/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}
/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}


// +++++++++++++++++++++++++++++++++ Theme css +++++++++++++++++++++++++++++++++ 

//--------------- Atomic css
.bg-primary{
  background-color: #07391D !important;
}
.bg-secondary{
  background-color: #0E6434 !important;
}
.bg-secondary-light{
  background-color: #198C36 !important;
}
.bg-tertiary{
  background-color: #F2EFE6 !important;
}
.bg-dark{
  background-color: #262626 !important;
}


.text-primary{
  color: #07391D !important;
}
.text-secondary{
  color: #0E6434 !important;
}
.text-secondaryLight{
  color: #198C36 !important;
}
.text-tertiary{
  color: #F2EFE6 !important;
}
.text-dark{
  color: #262626 !important;
}
.text-dark-secondary{
  color: #2D354A !important;
}
.text-dark:hover{
  color: #0E6434 !important;
}

.underline-none{
  text-decoration: none !important;
}
// -------------- form item style
.ant-form-item{
  margin-bottom: 0 !important;
}
// -------------- checkbox style
.ant-checkbox{
  .ant-checkbox-inner{
    border 1px solid #777;
  }

  &.ant-checkbox-checked{
    .ant-checkbox-inner{
      background-color: #07391d;
      border-color: #07391d;
    }
  } 
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: #07391d !important;
}

// -------------- Radio button style
.ant-radio-wrapper{
  color: #8E959B !important;
  text-transform: capitalize;
}
.ant-radio-inner {
  border-color: #07391d !important;
}
.ant-radio-checked{
  .ant-radio-inner {
    border-color: #07391d !important;
    &::after {
      background-color: #07391d;
    }
  }
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #07391d;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}

.section-padding{
  padding: 45px 0;
}


// -------------- btn styles
.ant-btn{
  text-shadow: none;
  box-shadow: none;
  padding: 5px 30px;
  font-size: 23px;
  font-weight: 500;
  line-height: 35px;
}
// -------------- btn colors
.ant-btn-secondaryLight{
    background-color: #198C36 !important;
    border: 1px solid #198C36 !important;
    color: #fff !important;
}
.ant-btn-secondaryLight:hover,
.ant-btn-secondaryLight:active,
.ant-btn-secondaryLight:focus{
  background-color: #07391D !important;
  border: 1px solid #07391D !important;
  color: #fff !important;
}
.ant-btn-secondaryLight[disabled]{
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  opacity: 0.65;
}
.ant-btn-primary{
  background-color: #07391D !important;
  border: 1px solid #07391D !important;
  color: #fff !important;
}
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary.selected{
background-color: #198C36 !important;
border: 1px solid #198C36 !important;
color: #fff !important;
}

// -------------- border radius
.rounded-15{
  border-radius: 15px !important;
}

// -------------- border
.border-0{
  border: 0 !important;
}

// -------------- btn height
.md-btn{
  height: 45px;
}
.btn-big{
  height: 45px !important;
  padding: 5px 30px !important;
  font-size: 23px !important;
  font-weight: 500 !important;
  line-height: 35px !important;
}
// --------- main heading
.main-heading{
  font-size: 32px;
}
// ---------  Divider
.white-divider{
  border-top: 1px solid #fff !important;
}
.grey-divider{
  border-top: 1px solid #B1B1B1 !important;
}

/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .main-heading{
    font-size: 25px;
  }
  // -------------- btn styles
  .ant-btn{
    padding: 5px 15px;
    font-size: 18px;
    line-height: 25px;
  }
  
  .md-btn{
    height: 40px;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .main-heading{
    font-size: 30px;
  }
  // -------------- btn styles
  .ant-btn{
    padding: 5px 25px;
    font-size: 20px;
    line-height: 25px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .main-heading{
    font-size: 30px;
  }
  // -------------- btn styles
  .ant-btn{
    padding: 5px 25px;
    font-size: 20px;
    line-height: 25px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
   
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}

`;

export const StudentContainer = styled.div `
.wrapper{
  background: #07391D;
  height: auto !important;
}

// ======================  .login.scss

.login-wrapper{
  padding: 20px 0;
  .main-heading{
    margin-bottom: 25px;
  }
}

.login-block{
  padding: 50px 40px;
  border-radius: 15px;
  .username-item, .password-item {
    margin-bottom: 30px !important;
  }
  .login-btn{
    margin-top: 50px;
  }
}

.ant-input{
  height: 50px;
  border-radius: 5px;
  border: 1px solid #F2EFE6;
}

.ant-input:hover{
  border: 1px solid #F2EFE6;
}

.ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #777777;
  text-transform: capitalize;
}

.ant-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #777777;
  text-transform: capitalize;
}

.ant-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #777777;
  text-transform: capitalize;
}

// ----- Select option style
.ant-select{
  :not(.ant-select-customize-input){
    .ant-select-selector{
      height: 50px;
      border-radius: 5px;
      border: 1px solid #F2EFE6;
    }
  } 
  :not(.ant-select-disabled):hover{
    .ant-select-selector{
      border-color: #F2EFE6;
    }
  }
}
.ant-select-single{
  &.ant-select-show-arrow{
    & .ant-select-selection-item, & .ant-select-selection-placeholder{
      line-height: 50px;
    }
  }
} 

.ant-select-focused{
  &:not(.ant-select-disabled){
    &.ant-select{
      &:not(.ant-select-customize-input){
        & .ant-select-selector {
          border-color: #40a9ff;
          box-shadow: '0 0 0 2px rgba(24, 144, 255, 0.2)';
        }
      } 
    }
  }
}

.ant-picker-calendar {
  .ant-select-selector {
    width: 100% !important;
    height: 32px !important;
    padding: 0 11px !important;
  }
  .ant-select-selection-item {
    line-height: 30px !important;
  }
}

// reset
.ant-input:focus, .ant-input-focused{
  border: 1px solid #198C36;
  box-shadow: none;
}

.footer-wrapper{
  text-align: center;
  background-color: transparent;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  color: #fff;
  padding: 25px 30px;
}

/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .login-wrapper{
    .main-heading{
      margin-bottom: 20px;
    }
  }
  
  .login-block{
    padding: 30px;
    .ant-input{
      margin-bottom: 20px;
    }
    .login-btn{
      margin-top: 25px;
    }
  }
  .ant-input{
    height: 40px;
  }

    
  // ----- Select option style
  .ant-select{
    :not(.ant-select-customize-input){
      .ant-select-selector{
        height: 40px;
      }
    } 
  }
  .ant-select-single{
    &.ant-select-show-arrow{
      & .ant-select-selection-item, & .ant-select-selection-placeholder{
        line-height: 40px;
      }
    }
  } 
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .login-wrapper{
    .main-heading{
      margin-bottom: 20px;
    }
  }
  
  .login-block{
    padding: 35px 35px;
    .ant-input{
      margin-bottom: 25px;
    }
    .login-btn{
      margin-top: 30px;
    }
  }
  .ant-input{
    height: 45px;
  }
  // ----- Select option style
  .ant-select{
    :not(.ant-select-customize-input){
      .ant-select-selector{
        height: 45px;
      }
    } 
  }
  .ant-select-single{
    &.ant-select-show-arrow{
      & .ant-select-selection-item, & .ant-select-selection-placeholder{
        line-height: 45px;
      }
    }
  } 
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .login-wrapper{
    .main-heading{
      margin-bottom: 25px;
    }
  }
  
  .login-block{
    padding: 40px 35px;
    .ant-input{
      margin-bottom: 25px;
    }
    .login-btn{
      margin-top: 30px;
    }
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 

  .login-wrapper{
    .main-heading{
      margin-bottom: 25px;
    }
  }
  
  .login-block{
    padding: 40px 35px;
    .ant-input{
      margin-bottom: 25px;
    }
    .login-btn{
      margin-top: 30px;
    }
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
   
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}

// ======================  .register.scss

.ant-steps-label-vertical{
  .ant-steps-item-icon{
    width: 38px;
    height: 38px;
    border: 3px solid #fff;
  }
  .ant-steps-item-tail{
    padding: 3.5px 16px;
  }
  .ant-steps-item-container{
    .ant-steps-item-icon{
      font-family: 'Poppins', sans-serif;
      line-height: 34px;
      .ant-steps-icon{
        font-weight: 500;
      }
    }
  }
  
} 
.ant-steps-item-tail::after{
  height: 3px;
}
.ant-steps-item-process{
  & > .ant-steps-item-container {
    & > .ant-steps-item-tail::after {
      background-color: #07391D;
    }
    & > .ant-steps-item-icon{
      background: #158E4A;
    }
    & .ant-steps-item-content {
      & > .ant-steps-item-title {
        color: #fff;
        font-weight: bold;
      }
    }
  }
} 

.ant-steps-item-finish {
  & > .ant-steps-item-container{
    & > .ant-steps-item-tail {
      &::after {
        background-color: #fff;
        font-weight: bold;
      }
    }
    & .ant-steps-item-content{
      & > .ant-steps-item-title{
        color: #fff;
      }
    } 
    & .ant-steps-item-icon{
      background-color: #158e4a;
      & .ant-steps-icon{
        color: #fff;
      }
    }
  } 
}

.ant-steps-item-wait {
  & .ant-steps-item-icon{
    background-color: #B1B1B1; 
    border: 3px solid #B1B1B1;
    & .ant-steps-icon{
      color: #000;
    }
  }
  & > .ant-steps-item-container {
    & > .ant-steps-item-tail::after {
      background-color: #07391D;
    }
    & > .ant-steps-item-content {
      & > .ant-steps-item-title {
        color: #B1B1B1;
      }
    }
  }
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 195px;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: 82px;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 105px;
}

.register-steps-wrapper{
  background-color: #0E6434;
  padding: 60px 0 50px 0;
}
.register-content-wrapper{
  padding: 40px 0;
}
.register-form-block{
  .register-heading-block{
    display: flex;
    align-items: center;
    gap: 10px;  
    .page-number{
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: #fff;
      font-size: 22px;
      color: #262626;
      line-height: 34px;
      flex-shrink: 0;
      display: inline-block;
      text-align: center;
    }
    .register-heading{
      font-size: 32px;
      color: #fff;
    }  
  }
}
.register-form{
  padding: 25px;
  margin-top: 25px;
  .register-sub-heading{
    font-size: 28px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
  }
  .course-description {
    p {
      margin: 0 !important;
    }
  }
  .shipping-options{
    .ant-radio-group{
      label{
        display: flex;
        font-size: 18px;
        font-weight: 400;
        color: #262626 !important;
        padding: 20px 0;
        span{
          &.ant-radio + *{
            padding-left: 30px !important;
          }
          span{
            color: #0E6434;
            font-weight: 500;
          }
        }
      }
    }
  }
  .payment-options{
    .ant-radio-group{
      label{
      display: flex;
      font-weight: 500;
      font-size: 20px;
      color: #262626 !important;
      padding: 20px 0;
      }
    }

  }
  .ant-form-item-label > label{
    font-size: 13px;
  }
}
.payment-form {
  .ant-form-item-label{
    padding: 0 !important;
  }
}
.question-form {
  .heading-badge{
    margin-bottom: 30px;
  }
}
.ccv-code-input .ant-input{
  margin-bottom: 10px !important;
}
.steps-button-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.disclaimer-container {
  color: white;
  flex: 0 0 auto;
  width: 45%;
  .disclaimer-text {
    color: white;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
  }
}
.summary-block{
  padding: 25px;
  border-top: #0e6434 solid 5px;
  margin-top: 25px;
  font-family: 'Source Sans Pro', sans-serif;
  .summary-heading{
    font-size: 23px;
    font-family: 'Poppins', sans-serif;
  }
  .course-name{
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
  }
  .ant-list{
    .ant-list-header{
      border-bottom: 1px solid #dadada;
    }
    .ant-list-item{
      &:last-child{
        .ant-list-item-meta-title,
        .ant-list-item-meta-description{
          font-size: 19px !important;
          font-weight: bold !important;
        }
      }
      &:not(:last-child){
        border-bottom: 1px solid #dadada;
      }
      .ant-list-item-meta-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .ant-list-item-meta-title{
          font-size: 15px;
          font-weight: 400;
          color: #262626;
        }
        .ant-list-item-meta-description{
          font-size: 15px;
          font-weight: bold;
          color: #262626;
          flex-shrink: 0;
        }
      }
    }
  }
}
.course-register-form{
  label {
    font-size: 16px !important;
  }
}
.btn-right{
  margin-left: auto;
}
.heading-badge{
  background: #0E6434;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0.25em 0.4em;
  border-radius: 0.25rem;
}
// -------------- Payment confirmation style
.payment-confirmation{
  font-size: 19px;
  .ant-list-split .ant-list-item{
    border-top: 1px solid #dadada;
    border-bottom: 0;
  }
  .payment-info{
    margin: 40px 0;
    font-family: 'Source Sans Pro', sans-serif;
    .ant-list-item-meta-content{
      display: flex;
      align-items: center;
      gap: 15px;
      .ant-list-item-meta-title {
        font-size: 19px;
        color: #262626;
        width: 245px;
        font-weight: 600;
        flex-shrink: 0;
      }
      .ant-list-item-meta-description{
        font-size: 19px;
        color: #262626;
      }
    }
  }
  small{
    font-family: 'Source Sans Pro';
    display: inline-block;
    margin-bottom: 16px;
  }
}


// -------------- label style
.ant-form-item-label{
  padding: 0;
  & > label {
    font-size: 16px;
    color: #8E959B;
    // text-transform: capitalize;
  }
}


// ---------- btn icon
.btn-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */


@media (max-width: 575.98px) { 
  .steps-button-group {
    margin-top: 30px;
  }
  .register-form{
    padding: 20px;
    .shipping-options{
      .ant-radio-group{
        label{
          font-size: 16px;
          padding: 12px 0;
          span{
            &.ant-radio + *{
              padding-left: 10px !important;
            }
          }
        }
      }
    }
    .payment-options{
      .ant-radio-group{
        label{
          font-size: 16px;
          padding: 12px 0;
        }
      }
  
    }
    .register-sub-heading {
      font-size: 24px;
    }
  }
  .payment-confirmation{
    font-size: 16px;
    .ant-list-split {
      .ant-list-item{
        padding: 7px 0;
      }
    }
    .payment-info {
      margin: 20px 0;
      .ant-list-item-meta-content{
        .ant-list-item-meta-title,
        .ant-list-item-meta-description {
          font-size: 18px;
          overflow-wrap: break-word;
        }
        .ant-list-item-meta-title{
          width: 110px;
        }
        .ant-list-item-meta-description {
          word-break: break-all;
          line-height: 21px;
        }
      }
    }
  } 
  .question-form{
    .heading-badge {
      margin-bottom: 20px;
    }
  }
  .white-divider{
    margin: 20px 0;
  }
  .register-form-block{
    .register-heading-block{
      .page-number{
        width: 30px;
        height: 30px;
        font-size: 20px;
      }
      .register-heading {
        font-size: 22px;
      }
    } 
  } 
  .summary-block{
    padding: 20px;
    .summary-heading {
      font-size: 22px;
    }
    .course-name {
      font-size: 20px;
    }
  } 
  .heading-badge {
    font-size: 18px;
    padding: 7px 8px;
    line-height: 22px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 135px;
  }
  .ant-steps-label-vertical .ant-steps-item-icon {
    margin-left: 50px;
  }
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 72px;
  }
  .ant-steps-item-title{
    font-size: 14px;
    line-height: 21px;
  }
  .ant-steps-item-wait{
    & > .ant-steps-item-container{
      & > .ant-steps-item-content{
        & > .ant-steps-item-title{
          line-height: 21px;
        }
      } 
    }
  }
  .register-steps-wrapper {
    padding: 30px 0 30px 0;
  }
  .ant-steps-item-process, .ant-steps-item-finish{
    .ant-steps-item-icon{
      border:3px solid #fff;
    }
  } 
  .ant-steps-vertical{
    & > .ant-steps-item{
      & > .ant-steps-item-content {
        line-height: 47px;
      }
      & > .ant-steps-item-container{
        & > .ant-steps-item-tail::after{
          width: 3px;
        }
      } 
    }
  } 
  .ant-steps-vertical{
    & > .ant-steps-item:not(:last-child){
      & > .ant-steps-item-container{
        margin-bottom: 20px;
      }
    } 
    & .ant-steps-item-icon{
      line-height: 26px;
    }
  } 
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .steps-button-group {
    margin-top: 30px;
  }
  .register-form{
    .shipping-options{
      .ant-radio-group{
        label{
          font-size: 16px;
          padding: 12px 0;
          span{
            &.ant-radio + *{
              padding-left: 10px !important;
            }
          }
        }
      }
    }
    .payment-options{
      .ant-radio-group{
        label{
          font-size: 16px;
          padding: 12px 0;
        }
      }
  
    }
    .register-sub-heading {
      font-size: 26px;
    }
  }
  .payment-confirmation{
    font-size: 18px;
    .ant-list-split {
      .ant-list-item{
        padding: 7px 0;
      }
    }
    .payment-info {
      margin: 20px 0;
      .ant-list-item-meta-content{
        .ant-list-item-meta-title,
        .ant-list-item-meta-description {
          font-size: 18px;
        }
        .ant-list-item-meta-title{
          width: 200px;
        }
      }
    }
  } 
  .question-form{
    .heading-badge {
      margin-bottom: 20px;
    }
  }
  .white-divider{
    margin: 20px 0;
  }
  .register-form-block{
    .register-heading-block{
      .register-heading {
        font-size: 28px;
      }
    } 
  } 
  .summary-block{
    .summary-heading {
      font-size: 22px;
    }
    .course-name {
      font-size: 20px;
    }
  } 
  .heading-badge {
    font-size: 22px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 135px;
  }
  .ant-steps-label-vertical .ant-steps-item-icon {
    margin-left: 50px;
  }
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 72px;
  }
  .ant-steps-item-title{
    font-size: 14px;
    line-height: 21px;
  }
  .ant-steps-item-wait{
    & > .ant-steps-item-container{
      & > .ant-steps-item-content{
        & > .ant-steps-item-title{
          line-height: 21px;
        }
      } 
    }
  }
  .register-steps-wrapper {
    padding: 40px 0 30px 0;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .steps-button-group {
    margin-top: 40px;
  }
  .register-form{
    .shipping-options{
      .ant-radio-group{
        label{
          padding: 15px 0;
          span{
            &.ant-radio + *{
              padding-left: 10px !important;
            }
          }
        }
      }
    }
    .payment-options{
      .ant-radio-group{
        label{
          padding: 15px 0;
        }
      }
    }
    .register-sub-heading {
      font-size: 26px;
    }
  }
  .payment-confirmation{
    .ant-list-split {
      .ant-list-item{
        padding: 7px 0;
      }
    }
    .payment-info {
      margin: 20px 0;
    }
  } 
  .question-form{
    .heading-badge {
      margin-bottom: 20px;
    }
  }
  .white-divider{
    margin: 20px 0;
  }
  .register-form-block{
    .register-heading-block{
      .register-heading {
        font-size: 30px;
      }
    } 
  } 
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 165px;
  }
  .ant-steps-label-vertical .ant-steps-item-icon {
    margin-left: 62px;
  }
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 84px;
  }
  .ant-steps-item-wait{
    & > .ant-steps-item-container{
      & > .ant-steps-item-content{
        & > .ant-steps-item-title{
          line-height: 21px;
        }
      } 
    }
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .steps-button-group {
    margin-top: 40px;
  }
  .register-form{
    .shipping-options{
      .ant-radio-group{
        label{
          padding: 15px 0;
          span{
            &.ant-radio + *{
              padding-left: 10px !important;
            }
          }
        }
      }
    }
    .payment-options{
      .ant-radio-group{
        label{
          padding: 15px 0;
        }
      }
  
    }
  }
  .payment-confirmation{
    .ant-list-split {
      .ant-list-item{
        padding: 7px 0;
      }
    }
    .payment-info {
      margin: 20px 0;
    }
  } 
  .question-form{
    .heading-badge {
      margin-bottom: 20px;
    }
  }
  .white-divider{
    margin: 20px 0;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
  .steps-button-group {
    margin-top: 40px;
  }
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}


// --------------- Account styles

.hero-heading{
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.hero-subheading{
  font-size: 23px;
  font-weight: 500;
  line-height: 35px;
  margin: 0 !important;
  text-transform: capitalize;
}
.inner-page-hero {
  padding: 50px 0;
  height: 185px;
  display: flex;
  align-items: center;
  &.loader-section {
    height: 70vh;
    .isoContentLoaderCircle {
      stroke: #ffffff;
    }
  }
}
.course-card{
  margin-bottom: 40px;
  .ant-card-head{
    padding: 20px 30px;
    border-bottom: 1px solid #fff !important;
    .ant-card-head-title{
      font-size: 25px;
      font-weight: 500;
      line-height: 38px;
      padding: 0 !important;
      color: #2D354A;
      text-transform: capitalize;
    }
  }
  .ant-card-body{
    .course-listing li{
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      align-items: center;
      .payment-badge-block{
        // text-align: center;
        // margin-left: 35%
        // margin:0 auto;
      }
      .payment-button-block{
        text-align: end;
      }
      &:before, &:after{
        display: none !important;
      }
      &:not(:last-child){
        margin-bottom: 15px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 15px;
      }
      .course-title{
        font-size: 25px;
        font-weight: 500;
        line-height: 38px;
        margin-bottom: 0;
      }
      .ant-btn{
        width: 135px;
        padding: 5px 10px;
      }
    }
  }
}
.badge-payment-status{
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 4px;
  padding: 2px 13px;
  height: 25px;
  min-width: 60px;
  text-align: center;
  text-transform: capitalize;
  &.badge-paid{
    color: #fff;
    background-color: #00AC37;
    border: 1px solid #00AC37;
  }
  &.badge-unpaid{
    color: #fff;
    background-color: #f21525;
    border: 1px solid #f21525;
  }
}
.contact-details{
  margin: 60px 0 35px 0;
  h5{
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 20px;
  }
  p{
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
  }
  a:hover{
    text-decoration: underline !important;
  }
}
.socialize-info{
  a{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #0E6434;
    border: 3px solid #fff;
    color: #fff;
    font-size: 20px;
    &:hover{
      color: #07391d;
    }
    &:not(:last-child){
      margin-right: 15px;
    }
  }
}

/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .section-padding {
    padding: 25px 0;
  }
  .inner-page-hero {
    padding: 30px 0;
    height: 125px;
  }
  .hero-heading {
    font-size: 25px;
    line-height: 30px;
  }
  .hero-subheading {
    font-size: 17px;
    line-height: 30px;
  }
  .course-card{
    margin-bottom: 25px;
    .ant-card-head{
      padding: 10px 20px;
      .ant-card-head-title {
        font-size: 20px;
      } 
    } 
    .ant-card-body{
      padding: 20px;
      .course-listing li{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px 5px;
        .payment-badge-block{
          // margin:0 auto;
        }
        .payment-button-block{
          width: 53%;
        }
        .course-title {
          font-size: 17px;
          line-height: 24px;
          width: 100%;
        }
      }
    } 
  }
  .contact-details {
    margin: 20px 0 10px 0;
    h5{
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  .socialize-info{
    a {
      font-size: 17px;
    }
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .section-padding {
    padding: 35px 0;
  }
  .inner-page-hero {
    padding: 35px 0;
    height: 135px;
  }
  .hero-heading {
    font-size: 28px;
  }
  .hero-subheading {
    font-size: 19px;
  }
  .course-card{
    margin-bottom: 30px;
    .ant-card-head{
      padding: 10px 20px;
      .ant-card-head-title {
        font-size: 20px;
      } 
    } 
    .ant-card-body{
      padding: 20px;
      .course-listing li{
        .course-title {
          font-size: 20px;
          line-height: 25px;
        }
      }
    } 
  }
  .contact-details {
    margin: 30px 0 10px 0;
    h5{
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  .socialize-info{
    a {
      font-size: 17px;
    }
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .section-padding {
    padding: 35px 0;
  }
  .inner-page-hero {
    padding: 40px 0;
    height: 165px;
  }
  .hero-heading {
    font-size: 30px;
  }
  .hero-subheading {
    font-size: 20px;
  }
  .course-card{
    margin-bottom: 30px;
    .ant-card-head{
      padding: 10px 20px;
      .ant-card-head-title {
        font-size: 20px;
      } 
    } 
    .ant-card-body{
      padding: 20px;
      .course-listing li{
        .course-title {
          font-size: 21px;
          line-height: 30px;
        }
      }
    } 
  }
  .contact-details {
    margin: 40px 0 20px 0;
    h5{
      font-size: 23px;
      margin-bottom: 15px;
    }
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-padding {
    padding: 40px 0;
  }
  .inner-page-hero {
    padding: 40px 0;
    height: 175px;
  }
  .course-card{
    .ant-card-head{
      .ant-card-head-title {
        font-size: 23px;
      } 
    } 
    .ant-card-body{
      .course-listing li{
        .course-title {
          font-size: 23px;
        }
      }
    } 
  }
  .contact-details {
    margin: 50px 0 25px 0;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {

}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}




// --------------- Timer styles
.timer-section{
  position: relative;
  padding: 255px 0;
}
.timer-section:before{
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 57, 29, 0.78);
}
.countdown-timer{
  .ant-statistic-content-value, .ant-typography{
    color: #fff;
  }
  .ant-statistic-content-value{
    font-size: 125px;
    font-weight: 500;
    line-height: 108px;
    margin-bottom: 70px;
  }
  .ant-typography{
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 75px;
  }
  .timer-button{
    height: 65px;
    padding: 12px 55px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    color: #000;
    border: 0;
  }
}

/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  // --------------- Timer styles
  .timer-section{
    padding: 50px 0;
  }
  .countdown-timer{
    .ant-statistic-content-value{
      font-size: 55px;
      line-height: 47px;
      margin-bottom: 30px;
    }
    .ant-typography{
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 30px;
    }
    .timer-button{
      height: 50px;
      padding: 9px 40px;
      font-size: 20px;
      line-height: 20px;
    }
  }
  
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  // --------------- Timer styles
  .timer-section{
    padding: 50px 0;
  }
  .countdown-timer{
    .ant-statistic-content-value{
      font-size: 60px;
      line-height: 51px;
      margin-bottom: 30px;
    }
    .ant-typography{
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 30px;
    }
    .timer-button{
      height: 50px;
      padding: 9px 40px;
      font-size: 20px;
      line-height: 20px;
    }
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  // --------------- Timer styles
  .timer-section{
    padding: 70px 0;
  }
  .countdown-timer{
    .ant-statistic-content-value{
      font-size: 80px;
      line-height: 67px;
      margin-bottom: 30px;
    }
    .ant-typography{
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 30px;
    }
    .timer-button{
      height: 50px;
      padding: 9px 40px;
      font-size: 20px;
      line-height: 20px;
    }
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  // --------------- Timer styles
  .timer-section{
    padding: 100px 0;
  }
  .countdown-timer{
    .ant-statistic-content-value{
      font-size: 95px;
      line-height: 78px;
      margin-bottom: 40px;
    }
    .ant-typography{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 45px;
    }
    .timer-button{
      height: 55px;
      padding: 12px 45px;
      font-size: 25px;
      line-height: 20px;
    }
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
   
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}

// --------------- Timer styles
.thank-you-section {
  position: relative;
  padding: 100px 0;
  .thank-you-content {
    .ant-typography {
      color: #fff;
      font-size: 28px;
      font-weight: 500;
      line-height: 35px;
      margin: 50px;
      span {
        font-size: 70px;
        font-weight: 900;
      }
    }
    .timer-button {
      height: 65px;
      padding: 12px 30px;
      font-family: Poppins;
      font-size: 25px;
      font-weight: 500;
      color: #000;
      border: 0;
    }
  }
}
.thank-you-section:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 57, 29, 0.78);
}

/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
   
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}

// --------------- Course styles
.course-chapter-tab{
  &.ant-tabs-top, &.ant-tabs-bottom{
    & > .ant-tabs-nav, & > div > .ant-tabs-nav{
      margin-bottom: 0;
    }
  } 

  &.ant-tabs-top {
    & > .ant-tabs-nav::before, & > div > .ant-tabs-nav::before{
      border-bottom: 0;
    }
  } 
  .ant-tabs-tab-btn, .ant-tabs-tab-remove{
    &:focus, &:active{
      color: #fff;
    }
  } 
  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #fff;
    }
  }
  & .ant-tabs-nav-operations{
    align-self: center !important;
  }
  &.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab{
    background-color: #0E6434;
    border: 0;
    border-radius: 10px 10px 0 0 !important;
    color: #fff;
    font-size: 33px;
    font-weight: 500;
    line-height: 50px;
    padding: 10px 25px;
    border-bottom: 0;
   
  }
  .ant-tabs-tab + .ant-tabs-tab{
    margin: 0 0 0 20px;
  }
  .ant-tabs-ink-bar{
    display: none;
  }
  &.ant-tabs{
    & > .ant-tabs-nav .ant-tabs-nav-more,
    & > div > .ant-tabs-nav .ant-tabs-nav-more{
      width: 50px;
      height: 50px;
      border-radius: 50rem;
      background: #f2efe6;
      font-size: 22px;
      line-height: 50px;
      color: #07391d;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  } 
  
  .ant-tabs-content-holder{
    background-color: #F2EFE6;
    color: #262626;
    border-radius: 0px 15px 15px 15px;
    padding: 30px 60px;
    text-align: start;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    img{
      margin-bottom: 25px;
      width: 100%;
    }
    .chapter-description ul{
      list-style: disc !important;
      margin-left: 50px;
    }
  }
}

.time-remaining{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 20px;
    color: #fff;
  .ant-statistic-content{
    font-size: 21px;
    color: #262626;
    background: #ffffff;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 600;
    line-height: 27px;
  }
}



/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .course-section{
    .steps-button-group{
      display: block;
      button{
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  .course-chapter-tab{
    h2{
      font-size: 21px;
    }
    &.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab{
      font-size: 18px;
      line-height: 36px;
      padding: 5px 20px;
    }
    .ant-tabs-content-holder{
      padding: 20px 20px;
      font-size: 16px;
      img{
        margin-bottom: 15px;
      }
    }

  }
  .time-remaining{
    font-size: 16px;
    .ant-statistic-content{
      font-size: 16px;
    }
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .course-chapter-tab{
    &.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab{
      font-size: 18px;
      line-height: 36px;
      padding: 5px 20px;
    }
    .ant-tabs-content-holder{
      padding: 25px 25px;
      font-size: 16px;
      img{
        margin-bottom: 15px;
      }
    }
  }
  .course-chapter-tab{
    h2{
      font-size: 24px;
    }
  }
  .time-remaining{
    font-size: 16px;
    .ant-statistic-content{
      font-size: 16px;
    }
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .course-chapter-tab{
    &.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab{
      font-size: 20px;
      line-height: 40px;
      padding: 5px 20px;
    }
    .ant-tabs-content-holder{
      padding: 25px 25px;
      font-size: 16px;
      img{
        margin-bottom: 20px;
      }
    }
  }
  .course-chapter-tab{
    h2{
      font-size: 26px;
    }
  }
  .time-remaining{
    font-size: 18px;
    .ant-statistic-content{
      font-size: 18px;
    }
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .course-chapter-tab{
    &.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab{
      font-size: 25px;
      line-height: 40px;
      padding: 5px 20px;
    }
    .ant-tabs-content-holder{
      padding: 30px 30px;
    }
  }
  .course-chapter-tab{
    h2{
      font-size: 28px;
    }
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
   
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}
// --------------- Quiz styles
.next-btn{
  text-align:end;
}
.radio-button-space{
  margin-right:80px;
  margin-bottom:30px;
}
.quiz-padding{
  padding:50px;
  margin-bottom:15px;
  .quiz-heading{
    font-size: 23px;
    font-weight: 500;
    margin-bottom:33px;
  }
  .question-block{
    .question-space{
      font-size: 18px;
      font-weight: 400;
      margin-bottom:25px;
    }
    .answer{
      margin-bottom:15px;
      .ant-checkbox + span {
        font-size:18px;
    }
    }
  }
  .btn-left{
    margin-right:0 ;
  }

}
.ant-btn-terriaryBlock{
  background-color: #262626 !important;
  border: 1px solid #262626 !important;
  color: #fff !important;
}
.ant-btn-terriaryBlock:hover,
.ant-btn-terriaryBlock:active,
.ant-btn-btn-terriaryBlock:focus{
  background-color: #198C36  !important;
  border: 1px solid #198C36  !important;
  color: #fff !important;
}

.button-space{
  display:flex !important;
  justify-content: space-between;
  align-items: center;
}
.radio-button-space span.ant-radio + * {
  font-size: 18px;
  color: #262626;
}
.question-block .ant-radio-wrapper {
  color: #262626 !important;
  font-size: 18px !important;
}
.text-area-space{
  i{
    color:#A5A5A5;
    font-size: 16px !important;
  }
  textarea{
    min-height:240px;
  }
  .ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
    margin-bottom: 20px !important;
  }
  .ant-input-textarea-show-count::after {
    float: left;
    color:#A5A5A5;
}
.large-space{
  margin-bottom:50px;
}
}
.answer .ant-space-vertical{
  gap:15px !important;

}
/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .quiz-padding {
    padding: 20px;
    margin-bottom: 15px;
}
.quiz-padding .question-block .answer {
  margin-bottom: 10px;
}
.quiz-padding .quiz-heading {
  margin-bottom: 20px;
}
// .quiz-padding .question-block .question-space {
//   margin-bottom: 15px;
// }
.quiz-padding .quiz-heading {
  margin-bottom: 15px;
  font-size: 20px;
}
.radio-button-space {
  margin-right: 40px;
  margin-bottom: 15px;
}
.text-area-space textarea {
  min-height: 100px;
}
.answer .ant-space-vertical {
  margin-bottom: 10px;
}
.quiz-padding .question-block .question-space {
  font-size: 17px;
  margin-bottom: 10px;
}
.quiz-padding .question-block .answer .ant-checkbox + span {
  font-size: 17px;
  padding-left:10px !important;
}
.question-block .ant-radio-wrapper {
  font-size: 17px !important;
}

 }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .quiz-padding {
    padding: 35px;
    margin-bottom: 15px;
}
.quiz-padding .question-block .answer {
  margin-bottom: 10px;
}
.quiz-padding .quiz-heading {
  margin-bottom: 20px;
}
.quiz-padding .question-block .question-space {
  margin-bottom: 15px;
}
.quiz-padding .quiz-heading {
  margin-bottom: 15px;
  font-size: 20px;
}
.radio-button-space {
  margin-right: 50px;
  margin-bottom: 25px;
}
.text-area-space textarea {
  min-height: 170px;
}
.answer .ant-space-vertical {
  margin-bottom: 15px;
}
.quiz-padding .question-block .question-space {
  font-size: 17px;
}
.quiz-padding .question-block .answer .ant-checkbox + span {
  font-size: 17px;
}
.question-block .ant-radio-wrapper {
  font-size: 17px !important;
}

}


/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
.quiz-padding {
    padding: 35px;
    margin-bottom: 15px;
}
.quiz-padding .question-block .answer {
  margin-bottom: 10px;
}
.quiz-padding .quiz-heading {
  margin-bottom: 20px;
}
.quiz-padding .question-block .question-space {
  margin-bottom: 15px;
}
.quiz-padding .quiz-heading {
  margin-bottom: 15px;
}
.radio-button-space {
  margin-right: 50px;
  margin-bottom: 25px;
}
.text-area-space textarea {
  min-height: 170px;
}
.answer .ant-space-vertical {
  margin-bottom: 15px;
}
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
   
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
  
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
   
}

@media (min-width: 2560px) {

}

`;