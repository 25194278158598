import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getStudentToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
const fakeApiCall = false; // auth0 or express JWT

export function* signinRequest() {
  yield takeEvery('STD_LOGIN_REQUEST', function* ({ payload }) {
    const { token } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: 'Profile',
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile',
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* signinSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    //yield localStorage.setItem('STD_ACCESS_TOKEN', payload.token.replace(/['"]+/g, ''));
  });
}

export function* signinError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* signout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield localStorage.removeItem('STD_ACCESS_TOKEN');
    // history.push('login');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getStudentToken().get('stdToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* studentSaga() {
  yield all([
    fork(checkAuthorization),
    fork(signinRequest),
    fork(signinSuccess),
    fork(signinError),
    fork(signout),
  ]);
}
