import logo from './images/metro_logo.png';
import paymentCard from './images/payment-card.png';
import timerBg from './images/timer-bg-image.png';
import videoBg from './images/video-img.png';
import CourseCompletedImage from './images/course-completed.png'
import DOT from './images/DOT.png'
import Forklift from './images/Forklift.png'

export default {
    logo,
    paymentCard,
    timerBg,
    videoBg,
    CourseCompletedImage,
    DOT,
    Forklift
}