import Swal from 'sweetalert2';
import moment from 'moment';
import { message } from 'antd';

class AppHelper {
  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  swalToast(datas = {}) {
    if (datas.type == 1) {
      var options = {
        title: datas.title ? datas.title : 'Are you sure?',
        text: datas.text ? datas.text : "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: datas.buttonName
          ? datas.buttonName
          : 'Yes, delete it!',
      };

      return Swal.fire(options);
    } else if (datas.type == 2) {
      return Swal.fire(datas.text);
    } else if (datas.type == 3) {
      return Swal.fire(datas.title, datas.text, datas.icon);
    } else if (datas.type === 4) {
      var options = {
        title: "Time's up!",
        text: `Your session has timed out. Please select continue then scroll to the bottom of the page and select ${datas.buttonText} when ready.`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Continue',
      };

      return Swal.fire(options);
    } else {
      const options = {
        ...datas,
        ...{},
      };
      return Swal.fire(options);
    }
  }

  notifyUser = (data = {}) => {
    const options = {
      ...data,
      ...{
        type: data.type ? data.type : 'success',
        content: data.content
          ? data.content
          : data.type === 'error'
          ? 'Error!'
          : 'Success!',
      },
    };
    message.open(options);
  };

  getNextPath({ id = '', zone = 'Lastchapter' }) {
    let path = '';
    if (zone == 'Chapter') {
      path = '/course/' + id;
    } else if (zone == 'Quiz') {
      path = '/quiz/' + id;
    } else if (zone == '24Hour') {
      path = '/timer/' + id;
    } else if (zone == 'Break') {
      path = '/short_break/' + id;
    } else if (zone == 'Lastchapter') {
      path = '/account';
    } else if (zone == 'Thankyou') {
      path = '/thank-you';
    }

    return path;
  }

  dateformat(type, date = '') {
    if (!date) {
      return date;
    }

    if (type == 1) return moment(date).format('MM/DD/YYYY');
    else if (type == 2) return moment(date).format('MM/DD/YYYY HH:mm:ss');
    else if (type == 3) return moment(date).format('YYYY-MM-DD');
    else if (type == 4) return moment(date);
    else if (type == 5) return moment();
    else if (type == 6) return moment(date).format('dddd, D MMMM');
    else if (type == 7) return moment.utc(date).format('MM/DD/YYYY hh:mm A');
  }

  checkboxValidator(_, value) {
    if (!value || value.length === 0) {
      return Promise.reject('Please select at least one option.');
    }
    return Promise.resolve();
  }

  getSiteType() {
    let siteType = '12hour';

    const storedSiteType = localStorage.getItem('site_type');
    if (
      (storedSiteType && storedSiteType === 'metro_dot') ||
      (process.env.REACT_APP_URL.includes(window.location.host) &&
        !window.location.pathname.includes('/webpanel'))
    ) {
      siteType = 'dot';
    }

    return siteType;
  }

  currency() {
    return '$';
  }

  formatTimeToAmPm(timeString) {
    let [hours, minutes, seconds] = timeString.split(':').map(Number);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes.toString().padStart(2, '0')} ${suffix}`;
  }
}

export default new AppHelper();
