import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import contactSagas from '@iso/redux/contacts/saga';
import mailSagas from '@iso/redux/mail/saga';
import notesSagas from '@iso/redux/notes/saga';
import todosSagas from '@iso/redux/todos/saga';
import scrumBoardSaga from '@iso/redux/scrumBoard/saga';
import quizSaga from '@iso/redux/quiz/saga';
import studentSaga from '@iso/redux/student/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    scrumBoardSaga(),
    quizSaga(),
    studentSaga(),
  ]);
}
